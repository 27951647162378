<template>
  <div class="box">
<!--    <img class="lb1" src="../images/lb1.jpg">-->
    <div class="boxLeft">
      <div class="boxTop">
        <ul>
          <div class="title">{{dates.navigationName}}</div>
          <li v-for="(item,index) in dates.witem" :key="index" :class="index == selectNum?'active':''" @click="selectItem(index)">
            <i class="el-icon-caret-right"></i>{{item.navigationName}}
          </li>
        </ul>
      </div>
      <div class="boxBottom">
        <div class="title">联系我们</div>
        <AboutUs />
      </div>
    </div>
    <div class="boxRight">
      <div class="topR">
        <div class="titleL">{{selectNumTitle?selectNumTitle:params.name}}</div>
        <ul class="titleR">
          <li><a href="javascript:;">当前位置： </a></li>
          <li @click="goIndex"><a href="javascript:;">首页 </a></li>
          <li><span>&nbsp; > &nbsp;</span></li>
          <li>{{params.name}}</li>
          <template v-if="selectNumTitle!='山峰介绍' && selectNumTitle != 'English'">
            <li><span>&nbsp; > &nbsp;</span></li>
            <li>{{selectNumTitle}}</li>
          </template>
        </ul>
      </div>
      <div class="bottomR">
        <template v-if="params.type==1">
          <div class="right_box" v-if="isList">
            <ul>
              <li v-for="(item,index) in list" :key="index" @click="goDetails(item.id)" v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)">
                <i class="circular el-icon-arrow-right"></i>
                <span class="text">{{item.journalisTitle}}</span>
                <span class="time">{{toDate(dates.createTime, 'yyyy-MM-dd')}}</span>
              </li>
            </ul>
            <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-size="query.size" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
          </div>
          <div class="list_details" v-else>
            <div class="details_box">
              <div class="title">{{details.journalisTitle}}</div>
              <div class="title_m" v-if="details.message">
                <span>信息来源：{{details.message}}</span>
                <span>发布日期：{{toDate(details.createTime, 'yyyy-MM-dd')}}</span>
              </div>
              <div class="ql-editor" v-html="text"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="details_box">
            <div class="title">{{details.journalisTitle}}</div>
            <div class="title_m" v-if="details.message">
              <span>信息来源：{{details.message}}</span>
              <span>发布日期：{{toDate(details.createTime, 'yyyy-MM-dd')}}</span>
            </div>
            <div class="ql-editor" v-html="text"></div>
          </div>
        </template>
      </div>
    </div>
    <div style="clear: both;margin-bottom: 20px;"></div>
  </div>
</template>
<script>
import AboutUs from "./aboutUs";
import { selectNavigaionList,getNavigationQueryId,websiteListGetJournalismId,getTextarea } from "@/api/template";
export default {
  name: "List",
  components: { AboutUs },
  data() {
    return {
      params: '',
      query: {
        "current": 1,
        "navigationId": '',
        "navigationType": '',
        "size": 20
      },
      total: 10,
      dates: [],
      details: {},
      leftList: [],
      list: [],
      selectNum: 0,
      selectNumTitle: '',
      isList: false,
      text:''
    };
  },
  created(){
    // this.params = JSON.parse(window.sessionStorage.getItem('navigationValue'))
    // console.log(this.params)
    this.params = this.$route.params
    this.init()
  },
  methods: {
    init(){
      if(this.params.type == 1){
        getNavigationQueryId(this.params.id).then(res =>{
          if(this.params.type == 1){
            if(res.data.witem.length > 0){
              this.query.navigationId = res.data.witem[0].id
              this.query.navigationType = res.data.witem[0].navigationType
            }
            this.dates = res.data?res.data:[]
            this.list = []
            this.selectNumTitle = this.dates.witem[this.params.index].navigationName
          }else {
            this.details = res.data?res.data:''
          }
          this.selectItem(this.params.index)
        }).catch(() =>{})
      }else {
        websiteListGetJournalismId(this.params.id).then(res =>{
          this.details = res.data
          this.getTextareaFun(res.data.journalisContent)
        }).catch(() =>{})
      }

    },
    changeActive(e){
      e.currentTarget.className = "active"
    },
    removeActive(e){
      e.currentTarget.className = ""
    },
    getTextareaFun(journalisContent){
      let params = {
        "journalisContent":journalisContent
      }
      getTextarea(params).then(res =>{
        this.text = res.data.dataHtml
      })
    },
    getSelectNavigaionList(){
      selectNavigaionList(this.query).then(res =>{
        if(res.data.records){
          this.list = res.data.records
          this.isList = true
        }else {
          this.details = res.data
          this.isList = false
          if(res.data.journalisContent){
            this.getTextareaFun(res.data.journalisContent)
          }

        }
        this.total = res.data.total
      }).catch(() =>{})

    },
    selectItem(index){
      this.selectNum = index
      this.query.current = 1
      this.query.navigationId = this.dates.witem[index].id
      this.query.navigationType = this.dates.witem[index].navigationType
      this.list = []
      this.selectNumTitle = this.dates.witem[index].navigationName
      this.getSelectNavigaionList()
    },
    handleSizeChange(size){
      this.query.size = size
      this.getSelectNavigaionList()
    },
    handleCurrentChange(current){
      this.query.current = current
      this.getSelectNavigaionList()
    },
    goIndex(){
      this.$router.push({name:'template-fifth'})
      window.sessionStorage.setItem('currentActive', '-100')
    },
    goDetails(id) {
      let data = {
        name: this.params.name,
        id: id,
      }
      window.sessionStorage.setItem('detailsValue', JSON.stringify(data))
      this.$router.push('/fifth-details')
    },
  },
};
</script>
<style lang="scss" scoped>
.box{
  width: 1024px;
  min-height: 420px;
  margin: 20px auto 0;
}
.lb1{
  display: block;
  width: 1024px;
  margin: 0 auto 20px;
}
.boxLeft{
  float: left;
  width: 245px;
  .boxTop{
    .title{
      font-size: 24px;
      font-weight: bold;
      color: #000;
      font-family: "宋体";
      border-bottom: 1px solid #ADADAD;
      padding-bottom: 10px;
    }
    li{
      line-height: 30px;
      margin-top: 10px;
      border-bottom: 1px solid #ADADAD;
      color: #666;
      cursor: pointer;
      font-weight: bold;
      font-size: 14px;
    }
    .active{
      background: #0f6F7F;
      color: #fff;
    }
  }
  .boxBottom{
    margin-top: 30px;
    font-size: 12px;
    color: #555555;
    .title{
      font-size: 24px;
      font-weight: bold;
      color: #000;
      font-family: "宋体";
      border-bottom: 1px solid #ADADAD;
      padding-bottom: 10px;
    }
    .aboutUsBox{
      margin-top: 10px;
      line-height: 24px;
    }
  }
}
.boxRight{
  float: right;
  width: 765px;
  .topR{
    height: 30px;
    border-bottom: 1px solid #ADADAD;
    .titleL{
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      color: #333;
      padding-right: 30px;
      height: 30px;
      line-height: 30px;
      border-bottom: 2px solid #0e3786;
    }
    .titleR{
      float: right;
      li{
        float: left;
      }
    }
  }
  .bottomR{
    .right_box{
      line-height: 24px;
      padding-top: 20px;
      li{
        width: 765px;
        height: 26px;
        height: 26px;
        cursor: pointer;
        border-bottom: 1px dotted #ddd;
        color: #666;
        .circular{
          float: left;
          color: #f00;
          font-weight: bold;
          margin-top: 5px;
        }
        .text{
          display: inline-block;
          float: left;
          width: 650px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 12px;
        }
        .time{
          float: right;
        }
      }
      .active{
        color: #f00;
      }
      .pagination{
        margin-top: 20px;
      }
    }
    .details_box{
      margin-top: 20px;
      .title{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #555;
      }
      .title_m{
        text-align: center;
        color: #999;
        text-align: center;
        border-bottom: 1px dotted #ccc;
        margin: 10px 0;
        padding-bottom: 10px;
      }
    }
  }
}






.l_nav{
  height: 40px;
  background-color: #f7f7f7;
  border-bottom: 1px #d2d2d2 solid;
  ul{
    width: 1200px;
    margin: 10px auto 0;
    li{
      float: left;
      line-height: 40px;
      span{
        padding: 0 10px;
      }
    }
    .active{
      background: #0f6F7F;
      color: #fff;
    }
    .title{
      color: #00A384;
    }
  }
}
.content{
  display: flex;
  flex-flow:row wrap;
  justify-content: start;
  width: 1160px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  .left_box{
    width: 233px;
    background: #fff;
    font-size: 16px;
    line-height: 40px;
    border: 1px solid #00A384;
    padding-bottom: 20px;
    border-top: none;
    .title{
      background: #00A384;
      color: #fff;
      padding: 0 16px;
    }
    li{
      padding: 0 10px;
      height: 40px;
      line-height: 40px;
      border: 1px #dddddd solid;
      margin: 20px 20px 0 20px;
      cursor: pointer;
    }
    .active{
      background: #0f6F7F;
      color: #fff;
    }
  }
  .right_box{
    width: 905px;
    margin-left: 20px;
    .title{
      border-bottom: 1px #d7d7d7 solid;
      height: 40px;
      p{
        float: left;
        border-bottom: 1px #00A384 solid;
        padding: 0 16px;
        line-height: 40px;
        font-size: 16px;
        color: #00A384;
      }
    }
    ul{
      padding: 20px;
      li{
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        .circular{
          float: left;
          width: 4px;
          height: 4px;
          border-radius: 100%;
          background: #00A384;
          margin-top: 13px;
          margin-right: 10px;
        }
        .text{
          float: left;
          width: 750px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .time{
          float: right;
        }
      }
      .border_b{
        border-bottom: 1px solid #ddd;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .list_details{
    width: 905px;
    margin-left: 20px;
  }
  .details_box{
    width: 100%;
    .title{
      color: #00A384;
      font-size: 26px;
      text-align: center;
      line-height: 80px;
      font-weight: bold;
    }
    .title_m{
      text-align: center;
      border-bottom: 1px solid #d7d7d7;
      padding: 20px;
      font-size: 14px;
      span{
        margin-right: 30px;
      }
    }
  }

  .ql-editor{
    padding: 20px 0;
    line-height: 30px;
  }
}
</style>
